@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSansKR-Regular.woff2") format("woff2"),
    url("./fonts/NotoSansKR-Regular.woff") format("woff"),
    url("./fonts/NotoSansKR-Regular.ttf") format("truetype");
  font-display: block;
}
html,
body,
div,
applet,
object,
iframe,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
  font-family: "NotoSans";
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
div {
  background-repeat: no-repeat;
}
#app {
  background-color: #d6dee8;
  min-height: 100%;
  min-width: 100%;
}
p,
label {
  line-height: 1.5em;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px inherit inset;
  transition: background-color 5000s ease-in-out 0s;
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background-color: transparent;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background: #555;
  visibility: visible;
}

.scrollbar {
  display: block;
}
.noScrollbar {
  display: block;
}
.noScrollbar::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}
.noScrollbar::-webkit-scrollbar-thumb {
}
.subScrollbar {
}
.subScrollbar::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background-color: transparent;
}
.subScrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}
.subScrollbar::-webkit-scrollbar-thumb {
  background: #f4911d;
  visibility: visible;
  border-radius: 10px;
}
